
import Form from '@/components/Forms/Form.vue';
import { View } from 'client-website-ts-library/plugins';
import { Config } from 'client-website-ts-library/services';
import { ContextItemType, CustomContextItem } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    Form,
  },
})
export default class AgencyFeesAndCharges extends Mixins(View) {
  private formData: FormConstructorData | null = null;

  mounted() {
    this.formData = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        ({
          Type: ContextItemType.CustomData,
          Key: 'FormUrl',
          Value: 'C:/inetpub/option9prolistcomau/assets/files/agency-fees-and-charges.pdf',
        } as CustomContextItem),
      ],
    };
  }
}
